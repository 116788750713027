import React from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';
import { AlertNotification, Col, colors, Popup, Row, XIcon } from '@commonsku/styles';

const ErrorAlertPopup = (props) => {
  const { index, message = 'Caught Error...', onClosePopup } = props;
  const zIndex = BASE_ZINDEX + index + 10;

  return (
    <Popup
      width={'auto'}
      height={'auto'}
      padding={'12px'}
      noHeader
      noCloseButton
      style={{ background: colors.errors[10], minWidth: 300, width: '80%' }}
      zIndex={zIndex}
      overlayZIndex={zIndex-10}
    >
      <Row>
        <Col xs={10}>
          <AlertNotification alertType='error' style={{ padding: 4 }}>
            {message}
          </AlertNotification>
        </Col>
        <Col xs={2} style={{ textAlign: 'right', alignSelf: 'end', }}>
          <XIcon onClick={onClosePopup} color={colors.errors.main} style={{ marginTop: 4, }} />
        </Col>
      </Row>
    </Popup>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
      if (ownProps.onClose) {
        ownProps.onClose();
      }
    }
  };
};

const ConnectedErrorAlertPopup = connect(null, mapDispatchToProps)(ErrorAlertPopup);
export default ConnectedErrorAlertPopup;
